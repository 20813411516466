(function () {
  'use strict';

  class ResultsCtrl {
    constructor($stateParams) {
      let vm = this;
      vm.ctrlName = 'ResultsCtrl';
      vm.classroomId = $stateParams.classroom_id;
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.reports.controller:ResultsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.results')
    .controller('ResultsCtrl', ResultsCtrl);
}());
